import { SNavigation, SStorage } from "servisofts-component";
import SSocket from "servisofts-socket";

export default class Actions {
    static getAll(props) {
        var reducer = props.state.servicioReducer;
        var data = reducer.data;
        if (!data) {
            if (reducer.estado == "cargando") return null;
            SSocket.send({
                component: "servicio",
                type: "getAll",
                estado: "cargando",
            })
            return null;
        }
        return data;
    }
    static getByKey(key, props) {
        var data = this.getAll(props);
        if (!data) return null;
        return data[key];
    }
    static registro(data, props) {
        SSocket.send({
            component: "servicio",
            type: "registrar",
            estado: "cargando",
            data: data
        })
    }
    static editar(data, props) {
        SSocket.send({
            component: "servicio",
            type: "editar",
            estado: "cargando",
            data: data
        })
    }
    static switchHabilitado({
        key_servicio,
        key_habilitado
    }, props) {
        SSocket.send({
            component: "servicio",
            type: "switchHabilitado",
            estado: "cargando",
            key_servicio: key_servicio,
            key_habilitado: key_habilitado
        })
    }
    static getServicioHabilitado(key, props) {
        var reducer = props.state.servicioReducer;
        var data = reducer.servicioHabilitado[key];
        if (!data) {
            if (reducer.estado == "cargando") return null;
            SSocket.send({
                component: "servicio",
                type: "getServicioHabilitado",
                estado: "cargando",
                key: key
            })
            return null;
        }
        return data;
    }
    static getCertificado(key, props) {
        var reducer = props.state.servicioReducer;
        var data = reducer.certificado[key];
        if (!data) {
            if (reducer.estado == "cargando") return null;
            SSocket.send({
                component: "servicio",
                type: "getCertificado",
                estado: "cargando",
                key_servicio: key
            })
            return null;
        }
        return data;
    }
    static registrarCertificado(key, pem, props) {
        SSocket.send({
            component: "servicio",
            type: "registrarCertificado",
            estado: "cargando",
            key_servicio: key,
            pem: pem
        })
    }
}