import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SIcon, SList, SLoad, SPage, SPopup, SScrollView2, SText, STheme, SView } from 'servisofts-component';
import Parent from '../index'
import Test from './Test';

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            test: {}
        };
    }
    getServicioHabilitado() {
        var data = Parent.Actions.getServicioHabilitado(this.props.key_servicio, this.props);
        if (!data) return <SLoad />
        return <SList data={data}
            buscador
            render={obj => {
                return <><SView card height={55} center row>
                    <SView width={50} height>

                    </SView>
                    <SView flex>
                        <SText fontSize={16}>{obj.servicio.nombre}</SText>
                    </SView>
                    <SView width={50} height center onPress={() => {
                        SPopup.confirm({
                            title: "Eliminar", message: `Esta seguro de eliminar el servicio ${obj.servicio.nombre}?`, onPress: () => {
                                Parent.Actions.switchHabilitado({
                                    key_servicio: this.props.key_servicio,
                                    key_habilitado: obj.servicio.key,
                                });
                            }
                        })
                    }}>
                        <SIcon name={"Delete"} height={30} fill={STheme.color.secondary} />
                    </SView>
                    <SView width={50} height center onPress={() => {
                        Test.handleTestServicio({ key_servicio: this.props.key_servicio, nombre: obj.servicio.nombre }).then((resp) => {
                            this.state.test[obj.servicio.nombre] = resp.time;
                            this.setState({ ...this.state })
                        }).catch(e => {
                            this.state.test[obj.servicio.nombre] = false;
                            this.setState({ ...this.state })
                        })
                    }}>
                        <SIcon name={"Wifi"} height={20} fill={STheme.color.secondary} style={{
                            transform: [{ rotate: "180deg" }]
                        }} />
                    </SView>
                    <SView width={50} height center onPress={() => {
                        Test.handleRestartServicio({ key_servicio: this.props.key_servicio, nombre: obj.servicio.nombre }).then((resp) => {
                            // this.setState({ ...this.state })
                        }).catch(e => {
                            // this.setState({ ...this.state })
                        })
                    }}>
                        <SIcon name={"Reload"} height={20} fill={STheme.color.secondary} style={{
                            transform: [{ rotate: "180deg" }]
                        }} />
                    </SView>
                    
                </SView>
                </>
            }}
        />
    }
    getNoHabilitados() {
        var data = Parent.Actions.getAll(this.props);
        var habilitados = Parent.Actions.getServicioHabilitado(this.props.key_servicio, this.props);
        if (!data) return <SLoad />
        if (!habilitados) return <SLoad />
        return <SList
            buscador
            data={data}
            filter={(obj) => !habilitados[obj?.key]}
            render={(obj) => {
                return <SView col={"xs-12"} card height={40} center row onPress={() => {
                    Parent.Actions.switchHabilitado({
                        key_servicio: this.props.key_servicio,
                        key_habilitado: obj.key,
                    }, this.props)
                    SPopup.close("item");
                }}>
                    <SView width={50} height>

                    </SView>
                    <SView flex>
                        <SText fontSize={16} bold>{obj.nombre}</SText>
                    </SView>

                    <SView width={40} height center>
                        <SIcon name={"Back"} height={25} fill={STheme.color.secondary} style={{
                            transform: [{ rotate: "180deg" }]
                        }} />
                    </SView>
                </SView>
            }}
        />
    }
    render() {
        if (!this.props.key_servicio) return null;

        return (
            <SView col={"xs-12"}>
                <SText fontSize={18}>{"Servicios habilitados"}</SText>
                <SHr height={8} />
                <SView card height={40} center row onPress={() => {
                    SPopup.open({
                        key: "item", content: <SView col={"xs-11 md-9 xl-7"} padding={16} height={800} style={{ maxHeight: "90%", }} backgroundColor={STheme.color.background} withoutFeedback center>
                            {this.getNoHabilitados()}
                        </SView>
                    })
                }}>
                    <SText color={STheme.color.lightGray}>{"Habilitar Servicio"}</SText>
                </SView>
                <SHr height={16} />
                {this.getServicioHabilitado()}
            </SView>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);