import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SPage, SText, SView, SDate, SInput, SPopup, STheme, SScrollView2, SIcon, SButtom, SLoad } from 'servisofts-component';
import Parent from '../index'

class Certificado extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    editarCertificado() {
        SPopup.open({
            key: "Certificado",
            content: <SView withoutFeedback col={"xs-11 md-9 xl-7"} height={400} style={{ maxHeight: "90%", }} backgroundColor={STheme.color.background} style={{
                borderRadius: 16,
            }}>
                <SScrollView2 disableHorizontal>
                    <SView col={"xs-12"} center>
                        <SHr height={16} />
                        <SText>{"Nuevo certificado"}</SText>
                        <SHr height={16} />
                        <SView col={"xs-11"} center>
                            <SInput type={"textArea"} customStyle={"calistenia"} label={"PEM"} style={{
                                height: 200
                            }} ref={(ref) => { this.input = ref }} isRequired />
                            <SHr />
                            <SButtom props={{
                                // variant: "confirm",
                                type: "outline"
                            }} onPress={() => {
                                if (!this.input.verify()) return null;
                                var pem = this.input.getValue();
                                Parent.Actions.registrarCertificado(this.props.key_servicio, pem, this.props);
                                SPopup.close("Certificado");
                            }}>Registrar</SButtom>
                        </SView>
                        {/* {this.getNoHabilitados()} */}
                        <SHr height={16} />

                    </SView>
                </SScrollView2>
            </SView>
        })
    }


    render() {
        if (!this.props.key_servicio) return null;
        var cert = Parent.Actions.getCertificado(this.props.key_servicio, this.props)
        if (!cert) return null;
        if (this.props.state.servicioReducer.estado == "cargando") return <SLoad />
        if (this.props.state.servicioReducer.estado == "error" && this.props.state.servicioReducer.type == "registrarCertificado"){
            this.props.state.servicioReducer.estado = "";
            SPopup.alert("Error al registrar certificado!")
        }
        if (!cert.pem) return <SView col={"xs-12"} center card style={{
            padding: 16,
        }} onPress={() => {
            this.editarCertificado()
        }}>
            <SText fontSize={18}>{"Registrar certificado SSL"}</SText>
        </SView>;

        return <SView col={"xs-12"} center card style={{
            padding: 16,
        }} >
            <SText fontSize={18}>{"Certificado SSL"}</SText>
            <SHr />
            <SText>{cert.subject.replace(/, /g, "\n")}</SText>
            <SHr />
            <SText >{"Valido"}</SText>
            <SText>{new SDate(cert.not_valid_before).toString("yyyy MON,dd hh:mm")} -  {new SDate(cert.not_valid_after).toString("yyyy MON,dd hh:mm")}</SText>
            <SHr />
            <SInput value={cert.fingerprint} type={"textArea"} customStyle={"calistenia"} label={"FingerPrint"} editable={false} style={{
                height: 50
            }} />
            <SInput value={cert.pem} type={"textArea"} customStyle={"calistenia"} label={"PEM"} editable={false} />
            <SView width={50} height={50} style={{
                position: "absolute",
                right: 0,
                top: 0,
                padding: 4,
            }} onPress={() => {
                this.editarCertificado()
            }}>
                <SIcon name={"Edit"} />
            </SView>
        </SView>
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(Certificado);