import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { SButtom, SForm, SNavigation, SPage, SPopup, SView, SText, SHr } from 'servisofts-component';
import Usuario from '..';
import LogoAnimado from '../../CargaPage/LogoAnimado';
import Splash from '../../../Components/BackgroundImage/splash'

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    getForm() {
        return <SForm
            ref={(ref) => { this.form = ref; }}
            props={{
                col: "xs-12"
            }}
            inputProps={{
                customStyle: "bateon",
            }}
            inputs={{
                usuario: {
                    label: "Usuario", isRequired: true, keyboardType: "email-address", autoCapitalize: "none", autoFocus: true, onKeyPress: (evt) => {
                        if (evt.key === "Enter") {
                            this.form.focus("password");
                        }
                    }
                },
                password: {
                    label: "Password", type: "password", isRequired: true, onKeyPress: (evt) => {
                        if (evt.key === "Enter") {
                            this.form.submit();
                        }
                    }
                },
            }}
            onSubmit={(data) => {
                if (data) {
                    Usuario.Actions.login(data);
                }
            }}
        />
    }


    render() {
        var error = Usuario.Actions.getError("login", this.props);
        if (error) {
            SPopup.alert("Usuario no encontrado, Verifique sus datos.");
        }
        if (Usuario.Actions.getUsuarioLogueado(this.props)) {
            SNavigation.replace("inicio");
            return null;
        }
        // return
        return (
            <SPage hidden>
                {/* <Splash /> */}
                <SView center>
                    <SView col={"xs-11 md-6 xl-4"} >
                        <SView col={"xs-8"}>
                            <SView height={100} />
                        </SView>
                        <SText fontSize={45} bold>SERVICIO - servisofts</SText>
                        <SText fontSize={18} >Control del micro-servicio servisofts.servicio.</SText>
                        <SHr/>
                        <SText >{`puerto socket:\t\t10001.`}</SText>
                        <SText >{`puerto web socket:\t\t20001.`}</SText>
                        <SText >{`puerto http:\t\t\t30001.`}</SText>
                        <SView height={62} />
                        {this.getForm()}
                        <SView height={5} />
                        <SView col={"xs-12"} row center>
                            <SButtom style={{ borderRadius: 35, width: "100%" }} props={{
                                type: "outline"
                            }} onPress={() => {
                                this.form.submit();
                            }}>Iniciar Sesión</SButtom>
                            <SView col={"xs-1"} />
                            <SView height={40} />
                            {/* <SButtom style={{borderRadius:35, width:"100%", fontSize:22}} props={{
                                type: "default" 
                            }} onPress={() => {
                                SNavigation.navigate("login");
                            }}>Recuperar mi contraseña</SButtom> */}
                            {/* <SView height={60} /> */}
                        </SView>
                        <SView height={60}></SView>
                    </SView>

                </SView>
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(Login);