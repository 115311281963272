import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SIcon, SInput, SLoad } from 'servisofts-component';
import { SButtom, SDate, SForm, SNavigation, SPage, SPopup, SText, STheme, SView, SImage } from 'servisofts-component';

import Parent from '../index'
import Certificado from './Certificado';
import ServiciosHabilitados from './ServiciosHabilitados';
import Test from './Test';
class Registro extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.key = SNavigation.getParam("key");

    }
    componentDidMount() {
        // this.props.state.senoritaReducer.estado == "";
    }

    getForm() {
        var data = {};
        if (this.key) {
            data = Parent.Actions.getByKey(this.key, this.props);
            if (!data) return <SLoad />
        }
        return <SForm
            ref={(ref) => { this.form = ref; }}
            props={{
                col: "xs-12",
            }}
            inputProps={{
                customStyle: "calistenia",
            }}
            inputs={{
                // foto_p: { label: "Foto Perfil", type: "image", isRequired: true, height: 200, defaultValue: `${SSocket.api.root}senorita_${data.key}` },
                nombre: { label: "Nombre", isRequired: true, defaultValue: data.nombre },
                descripcion: { label: "Descripción", isRequired: true, defaultValue: data.descripcion, type: "textArea" },
                test_enabled: { label: "Habilitar test", defaultValue: !!data.test_enabled, type: "checkBox" },
            }}
            onSubmit={(values) => {
                if (this.key) {
                    Parent.Actions.editar({
                        key: this.key,
                        ...values,
                    }, this.props);

                } else {
                    Parent.Actions.registro(values, this.props);
                }

            }}
        />
    }

    render() {

        // if (this.props.state.senoritaReducer.estado == "exito" && this.props.state.senoritaReducer.type == "registro") {
        //     this.props.state.senoritaReducer.estado = "";
        //     var item = this.props.state.senoritaReducer.lastRegister;
        //     this.subirFoto(item.key);
        //     SNavigation.goBack();
        // }
        // if (this.props.state.senoritaReducer.estado == "exito" && this.props.state.senoritaReducer.type == "editar") {
        //     this.props.state.senoritaReducer.estado = "";
        //     this.subirFoto(this.key);
        //     SNavigation.goBack();
        // }

        return (
            <SPage title={`${this.key ? "Edit de" : "Registro de"} ${Parent.name}`}>
                {/* <Splash /> */}
                {/* <SView col={"xs-12"} style={{
                    position: "absolute",
                    bottom: 0,
                }}>
                    <SIcon name={"Bg1"} width={"100%"} />
                </SView> */}

                <SView center>
                    <SView col={"xs-11 md-6 xl-4"} >
                        <SHr h={16}/>
                        <SView row col={'xs-12'}>
                            <SText>{"Test data base= "}</SText>
                            <SView width={50} height center onPress={() => {
                                Test.handleTestBD({ key_servicio: this.key }).then((resp) => {
                                    console.log(resp);
                                }).catch(e => {
                                    console.error(e);
                                })
                            }}>
                                <SIcon name={"Wifi"} height={20} fill={STheme.color.secondary} style={{
                                    transform: [{ rotate: "180deg" }]
                                }} />
                            </SView>
                            <SView width={50} height center onPress={() => {
                                Test.handleRestartBD({ key_servicio: this.key }).then((resp) => {
                                    console.log(resp)
                                }).catch(e => {
                                    console.error(e);
                                    // this.setState({ ...this.state })
                                })
                            }}>
                                <SIcon name={"Reload"} height={20} fill={STheme.color.secondary} style={{
                                    transform: [{ rotate: "180deg" }]
                                }} />
                            </SView>
                        </SView>
                        <SView height={8} />
                        {this.getForm()}
                        <SView height={3} />

                        <SView col={"xs-12 md-12 xl-12"} row center >
                            {/* {this.getEliminar()} */}
                            <SView height={16} />
                            <SButtom
                                props={{
                                    type: "outline",
                                }}
                                onPress={() => {
                                    this.form.submit();
                                }}
                            >{(this.key ? "Editar" : "Registrar")}</SButtom>
                        </SView>
                        <SHr height={16} />


                        {/* <SHr height={1} color={"#999"} /> */}
                        <SHr height={16} />
                        <Certificado key_servicio={this.key} />
                        <SHr height={16} />
                        {/* <SHr height={1} color={"#999"} /> */}
                        <SHr height={16} />

                        <ServiciosHabilitados key_servicio={this.key} />
                        <SHr height={32} />
                    </SView>
                </SView>
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(Registro);