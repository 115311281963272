import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SHr, SIcon, SLoad, SNavigation, SPage, STable2, SText, SView } from 'servisofts-component';
import Servicios from '..';
import FloatButtom from '../../../Components/FloatButtom';

class Lista extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getLista() {
        var data = Servicios.Actions.getAll(this.props);
        if (!data) return <SLoad />
        return <STable2
            rowHeight={28}

            cellStyle={{
                padding: 4,
            }}
            header={[
                { key: "index", label: "#", width: 30, },
                { key: "nombre", label: "nombre", width: 150, component: e => <SText bold>{e}</SText> },
                { key: "puerto", label: "Puerto Socket", width: 60, center: true, order: "asc" },
                { key: "puerto_ws", label: "Puerto WS", width: 60, center: true },
                { key: "puerto_http", label: "Puerto HTTP", width: 60, center: true },
                { key: "ip", label: "IP Local", width: 100, center: true },
                { key: "ip_public", label: "IP Public", width: 100, center: true },
                {
                    key: "session", label: "Conectado", width: 80, center: true, component: (item) => {
                        return <SView width={22} height={22} backgroundColor={!item ? "#600" : "#060"} style={{
                            borderRadius: 8,
                        }}>
                        </SView>
                    }
                },
                {
                    key: "key-ver", label: "ver", width: 50, center: true, component: (item) => {
                        return <SView width={22} height={22} onPress={() => {
                            SNavigation.navigate("servicios/registro", { key: item })
                        }}>
                            <SIcon name={'Edit'} />
                        </SView>
                    }
                },
                // {
                //     key: "key-test", label: "test", width: 50, center: true, component: (item) => {
                //         return <SView width={22} height={22} onPress={() => {
                //             SNavigation.navigate("servicios/test", { pk: item })
                //         }}>
                //             <SIcon name={'Alert'} />
                //         </SView>
                //     }
                // },
                { key: "fecha_last", label: "Ultima Conexion", width: 100, render: (val) => !val ? "" : new SDate(val).toString("yyyy/MM/dd hh:mm") },

            ]}
            data={data}

            limit={100}
        />
    }

    render() {
        return (
            <SPage title={'Servicios'} disableScroll>
                <SView col={"xs-12"} center height>
                    {this.getLista()}
                </SView>
                <FloatButtom label={"+"} style={{ bottom: 40 }} onPress={() => {
                    SNavigation.navigate("servicios/registro")
                }} />
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(Lista);