
import PermisoCrearPage from './PermisoCrearPage';
import PermisoPage from './PermisoPage';
import PermisoPagePage from './PermisoPagePage';
import PermisoPageRegistroPage from './PermisoPageRegistroPage';
import RolPage from './RolPage';
import RolRegistroPage from './RolRegistroPage';

export default {
    PermisoCrearPage,
    PermisoPage,
    PermisoPagePage,
    PermisoPageRegistroPage,
    RolPage,
    RolRegistroPage
}