import { SPageListProps } from 'servisofts-component'
import Actions from './Actions'

import Lista from './Pages/Lista'
import Registro from './Pages/Registro'
import Test from './Pages/Test'
const Pages: SPageListProps = {
    "servicios": Lista,
    "servicios/registro": Registro,
    "servicios/test": Test,
}

import servicioReducer from './Reducer/servicioReducer'
const Reducers = {
    servicioReducer
}


export default {
    name:"servicio",
    Pages,
    Actions,
    Reducers
};