import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { SDate, SHr, SList, SPage, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Container from '../../Components/Container';

export default class root extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        SSocket.sendPromise({
            component: "servicio",
            type: "getAll"
        }).then(e => {
            this.setState({ data: e.data })
        }).catch(e => {
            console.error(e);
        })
    }
    renderItem(obj) {
        return <SView col={"xs-12"} card padding={8} onPress={() => {

        }}>
            <SView row col={"xs-12"}>
                <SText bold fontSize={16}>{(obj.nombre ?? "").toUpperCase()}</SText>

                <SView flex />
                <SText fontSize={12} color={STheme.color.gray}>Hace {new SDate(obj.fecha_last, "yyyy-MM-ddThh:mm:ss").timeSince(new SDate())}</SText>
            </SView>

            <SHr />
            <SText fontSize={10} color={STheme.color.gray}>{new SDate(obj.fecha_last, "yyyy-MM-ddThh:mm:ss").toString("dd de MONTH del yyyy a las HH")}</SText>
            <SHr h={4} />
            {/* <SText fontSize={10}>{(obj.certificado.vence ?? "")}</SText> */}
        </SView>
    }
    render() {
        return <SPage>
            <Container loading={!this.state.data}>
                <SHr />
                <SList
                    buscador
                    data={this.state.data}
                    filter={e => !!e.test_enabled}
                    render={this.renderItem.bind(this)}
                />
            </Container>
        </SPage>
    }
}
