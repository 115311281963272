import { SPage, SPageListProps } from 'servisofts-component'

import InicioPage from "./InicioPage";
import CargaPage from './CargaPage/index';
import Usuario from './Usuario';
import SSRolesPermisosPages from '../SSRolesPermisos/Pages';
import AjustesPage from './AjustesPage';

import Servicios from './Servicios';
import Test from './Test';
const newPages = SPage.combinePages("/", {
    ...Test
})

const Pages: SPageListProps = {
    "inicio": InicioPage,
    "carga": CargaPage,
    AjustesPage,
    ...Usuario.Pages,
    ...SSRolesPermisosPages,
    ...Servicios.Pages,
    ...newPages
}


export const Reducers = {
    ...Usuario.Reducers,
    ...Servicios.Reducers
}
export default Pages;