import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { SButtom, SHr, SNavigation, SNotification, SPage, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Container from '../../../Components/Container';

export default class Test extends Component {


    static handleTest({ test, key_servicio }) {
        return new Promise((resolve, reject) => {
            SNotification.send({
                key: test,
                title: `Test:${test}`,
                body: "Realizando test, porfavor espere.",
                type: "loading",
            })
            SSocket.sendPromise({
                component: "servicio",
                type: "test",
                test: "http",
                key_servicio: this.pk,
            }).then(e => {
                if (!e?.data?.live) throw "Error";
                SNotification.remove(test);
                resolve({ live: true, time: e?.data?.time })
                SNotification.send({
                    key: test,
                    title: `Test:${test}`,
                    body: "Test succes, time: " + (e?.data?.time ?? 0),
                    color: STheme.color.success,
                    time: 5000
                })
                console.log(e);
            }).catch(e => {
                reject({ live: false })
                SNotification.remove(test);
                SNotification.send({
                    key: test,
                    title: `Test:${test}`,
                    body: "Test failed.",
                    color: STheme.color.danger,
                    time: 5000
                })
                console.log(e);
            })
        })
    }
    static handleTestBD({ test = "BD", key_servicio }) {
        return new Promise((resolve, reject) => {
            SNotification.send({
                key: test,
                title: `testBD:${test}`,
                body: "Realizando test, porfavor espere.",
                type: "loading",
            })
            SSocket.sendPromise({
                component: "servicio",
                type: "testBD",
                key_servicio: key_servicio,
            }).then(e => {
                if (!e?.data?.live) throw "Error";
                SNotification.remove(test);
                resolve({ live: true, time: e?.data?.time })
                SNotification.send({
                    key: test,
                    title: `testBD:${test}`,
                    body: "Test succes, time: " + (e?.data?.time ?? 0),
                    color: STheme.color.success,
                    time: 5000
                })
                console.log(e);
            }).catch(e => {
                reject({ live: false })
                SNotification.remove(test);
                SNotification.send({
                    key: test,
                    title: `testBD:${test}`,
                    body: "Test failed.",
                    color: STheme.color.danger,
                    time: 5000
                })
                console.log(e);
            })
        })
    }
    static handleRestartBD({ test = "BD", key_servicio }) {
        return new Promise((resolve, reject) => {
            SNotification.send({
                key: test,
                title: `restartBD:${test}`,
                body: "Realizando test, porfavor espere.",
                type: "loading",
            })
            SSocket.sendPromise({
                component: "servicio",
                type: "restartBD",
                key_servicio: key_servicio,
            }).then(e => {
                if (!e?.data?.live) throw "Error";
                SNotification.remove(test);
                resolve({ live: true, time: e?.data?.time })
                SNotification.send({
                    key: test,
                    title: `restartBD:${test}`,
                    body: "Test succes, time: " + (e?.data?.time ?? 0),
                    color: STheme.color.success,
                    time: 5000
                })
                console.log(e);
            }).catch(e => {
                reject({ live: false })
                SNotification.remove(test);
                SNotification.send({
                    key: test,
                    title: `restartBD:${test}`,
                    body: "Test failed.",
                    color: STheme.color.danger,
                    time: 5000
                })
                console.log(e);
            })
        })
    }
    static handleTestServicio({ key_servicio, nombre }) {
        return new Promise((resolve, reject) => {
            SNotification.send({
                key: nombre,
                title: `Test:${nombre}`,
                body: "Realizando test, porfavor espere.",
                type: "loading",
            })
            SSocket.sendPromise({
                component: "servicio",
                type: "testServicio",
                key_servicio: key_servicio,
                nombre: nombre
            }).then(e => {
                if (!e?.data?.live) throw "Error";
                resolve({ live: true, time: e?.data?.time })
                SNotification.remove(nombre);
                SNotification.send({
                    key: nombre,
                    title: `TestServicio:${nombre}`,
                    body: "Test succes, time: " + (e?.data?.time ?? 0),
                    color: STheme.color.success,
                    time: 5000
                })
                console.log(e);
            }).catch(e => {
                reject({ live: false })
                SNotification.remove(nombre);
                SNotification.send({
                    key: nombre,
                    title: `TestServicio:${nombre}`,
                    body: "Test failed.",
                    color: STheme.color.danger,
                    time: 5000
                })
                console.log(e);
            })
        })
    }
    static handleRestartServicio({ key_servicio, nombre }) {
        return new Promise((resolve, reject) => {
            SNotification.send({
                key: nombre,
                title: `Restart:${nombre}`,
                body: "Reiniciando servicio, porfavor espere.",
                type: "loading",
            })
            SSocket.sendPromise({
                component: "servicio",
                type: "restartServicio",
                key_servicio: key_servicio,
                nombre: nombre
            }).then(e => {
                if (!e?.data?.live) throw "Error";
                resolve({ live: true, time: e?.data?.time })
                SNotification.remove(nombre);
                SNotification.send({
                    key: nombre,
                    title: `Restart:${nombre}`,
                    body: "Restart succes, time: " + (e?.data?.time ?? 0),
                    color: STheme.color.success,
                    time: 5000
                })
                console.log(e);
            }).catch(e => {
                reject({ live: false })
                SNotification.remove(nombre);
                SNotification.send({
                    key: nombre,
                    title: `Restart:${nombre}`,
                    body: "Restart failed.",
                    color: STheme.color.danger,
                    time: 5000
                })
                console.log(e);
            })
        })
    }


    constructor(props) {
        super(props);
        this.state = {
        };
        this.pk = SNavigation.getParam("pk")
    }

    componentDidMount() {
        SSocket.sendPromise({
            component: "servicio",
            type: "getByKey",
            key: this.pk,
        }).then(e => {
            if (!e.data) throw "Data not found";
            this.setState({ data: e.data })
        }).catch(e => {
            SNotification.send({
                title: "No pudimos optener el servicio.",
                body: "Ocurrion un error al optener el servicio del servidor intente nuevamente.",
                color: STheme.color.danger,
                time: 5000
            })
        })
    }

    render() {
        const data = this.state.data ?? {};
        return <SPage title={"Servicio Test"}>
            <Container>
                <SHr />
                <SView col={"xs-12"}>
                    <SText bold fontSize={18}>{data.nombre}</SText>
                    <SText color={STheme.color.lightGray}>{data.descripcion}</SText>
                </SView>
                <SHr />
                <SView row col={"xs-12"}>

                    <SButtom type='outline' onPress={this.handleTest.bind(this, { test: "http", key_servicio: this.key_servicio })}>{"HTTP"}</SButtom>
                    <SView width={8} />
                    <SButtom type='outline' onPress={this.handleTestServicio.bind(this, { nombre: "usuario", key_servicio: this.key_servicio })}>{"USUARIO"}</SButtom>
                    <SView width={8} />
                </SView>
            </Container>
        </SPage>
    }
}
