import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { SButtom, SForm, SNavigation, SPage, SPopup, SView, SText } from 'servisofts-component';
import Usuario from '..';
import LogoAnimado from '../../CargaPage/LogoAnimado';
import Splash from '../../../Components/BackgroundImage/splash'

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        var error = Usuario.Actions.getError("login", this.props);
        if (error) {
            SPopup.alert("Usuario no encontrado, Verifique sus datos.");
        }
        if (Usuario.Actions.getUsuarioLogueado(this.props)) {
            SNavigation.replace("inicio");
            return null;
        }
        return (
            <SPage hidden>
                {/* <Splash /> */}
                <SView center>
                    <SView col={"xs-11 md-6 xl-4"} >
                        <SView col={"xs-12"} height={"40%"}>
                        </SView>
                        <SText fontSize={45}>BATEON</SText>
                        <SText fontSize={18} >Es la primera aplicación móvil para encontrar y realizar tus fantasías.</SText>
                        <SView height={32} />
                        <SView height={16} />
                        <SView col={"xs-12"} row center>
                            <SButtom style={{ borderRadius: 35, width: "100%", }} props={{
                                type: "outline"
                            }} onPress={() => {
                                SNavigation.navigate("login");
                            }}>Iniciar Sesión</SButtom>
                            <SView col={"xs-1"} />
                            <SView height={20}></SView>
                            <SButtom style={{ borderRadius: 35, width: "100%" }} props={{
                                type: "outline"
                            }} onPress={() => {
                                SNavigation.navigate("registro");
                            }}>Registrarme</SButtom>

                        </SView>
                        <SView height={60}></SView>
                    </SView>

                </SView>
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(Index);